
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');
@import '~primeicons/primeicons.css';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$eurotravel-companion-primary: mat-palette($mat-teal, 300, 400, 100);
$eurotravel-companion-accent: mat-palette($mat-teal, 900, 800, 900);

// The warn palette is optional (defaults to red).
$eurotravel-companion-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$eurotravel-companion-theme: mat-light-theme($eurotravel-companion-primary, $eurotravel-companion-accent, $eurotravel-companion-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($eurotravel-companion-theme);

/* You can add global styles to this file, and also import other style files */

:root ::ng-deep{
    //font-family: 'Open Sans', sans-serif;
    font-family: 'Nunito Sans', sans-serif !important;
    font-size: 1em;
}

body {
    margin: 0;
    background-color: #fcfbfd;
    font-family: 'Nunito Sans', sans-serif !important;
}

.container {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    max-width: 1000px;
    margin: auto;
    font-family: 'Nunito Sans', sans-serif !important;
}

.content-wrapper {
    padding: 8px 24px;
}

.container-default {
    background-color: #fefefe;
    border-radius: 24px 24px 24px 24px;
    padding: 1em 4vw 1em 4vw;
    position: relative;
    margin-top: -24px;
    box-shadow: 0px 0px 16px rgba(0,0,0,0.2);
}

.mat-card, .mat-tab-group {
    font-family: 'Nunito Sans', sans-serif !important;
}

html, body { height: 100%; }
//body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
